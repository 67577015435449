import React, { useCallback, useEffect, useState } from 'react';
import styles from './Download.module.css';
import Header from '../Header';
import Axios from 'axios';
import { saveAs } from 'file-saver';
import { useHistory, useParams } from 'react-router-dom';

interface Params {
  token: string;
}

interface DownloadLink {
  url: string;
  filename: string;
}

const replacements: Record<string, string> = {
  'https://download-dev.photoday.io/': '/dev/',
  'https://download-staging.photoday.io/': '/staging/',
  'https://download.photoday.io/': '/prod/'
};

const Download: React.FC = () => {
  const { token } = useParams<Params>();
  const history = useHistory();
  const api = process.env.REACT_APP_BASE_URL;
  const [downloading, setDownloading] = useState<Record<string, boolean>>({});
  const [items, setItems] = useState<Array<DownloadLink> | null>(null);

  const isMobile = /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent);
  const isTablet = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent);
  const showDownloadAll = !isMobile && !isTablet;

  const fetchResponse = useCallback(async () => {
    if (!api) return history.push('/not-found');
    const response = await Axios.get(`${api}order-downloads/${token}`, {
      validateStatus: null
    });
    if (response.status !== 200) return history.push('/not-found');
    let items: Array<DownloadLink> = response.data;
    if (items.length === 0) return history.push('/not-found');
    items = items.map((item) => {
      Object.keys(replacements).forEach((key) => {
        item.url = item.url.replace(key, replacements[key]);
      });
      return item;
    });
    setItems(items);
    return items;
  }, [token, api, history, setItems]);

  useEffect(() => {
    fetchResponse().then((items) => {
      if (items) console.log(`Found ${items.length}`);
      else console.log('Could not fetch items');
    });
  }, [fetchResponse, token]);

  const timeout = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const setDownloadState = useCallback(
    (key: string, state: boolean) => {
      const newValues = downloading;
      newValues[key] = state;
      setDownloading({ ...{}, ...newValues });
    },
    [downloading, setDownloading]
  );

  const onDownload = useCallback(
    async (url: string, filename: string) => {
      if (!items) return;
      try {
        const random = [...Array(30)].map(() => Math.random().toString(36)[2]).join('');
        url = `${url}?cache=${random}`;
        if (!navigator.userAgent.match('CriOS')) {
          saveAs(url, filename, { autoBom: true });
        } else {
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          a.target = '_blank';
          a.click();
        }
      } finally {
        setDownloadState(url, false);
      }
    },
    [setDownloadState, items]
  );

  const onDownloadAll = useCallback(async () => {
    if (!items) return;
    try {
      setDownloadState('all', true);
      for (const link of items) {
        await onDownload(link.url, link.filename);
        await timeout(500);
      }
    } finally {
      setDownloadState('all', false);
    }
  }, [setDownloadState, items, onDownload]);

  if (!items) {
    return (
      <div className="container">
        <Header />
        <div>
          <div className={styles.grid}>
            <div />
            <div>
              <h1>Hello there!</h1>
              <p className={styles.bannerInfo}>We're rounding up your image downloads. This may take a minute.</p>
            </div>
            <div />
            <div />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <Header />
      <div>
        <div className={styles.grid}>
          <div />
          <div>
            <h1>Purchased Image Downloads:</h1>
          </div>
          <div />
          <div />
        </div>
        <div className={styles.grid}>
          <div />
          <div>
            <div className={styles.imagesSection}>
              {items?.map((link, index) => {
                return (
                  <div className={styles.linkSection} key={index}>
                    <img style={{ objectFit: 'contain', width: 100, height: 100 }} src={link.url} alt={link.filename} />
                    <p style={{ alignSelf: 'center' }}>File name: {link.filename}</p>
                    {!downloading[link.url] && (
                      <button
                        style={{ alignSelf: 'center' }}
                        className={styles.downloadButton}
                        onClick={async () => {
                          await onDownload(link.url, link.filename);
                        }}
                      >
                        Download
                      </button>
                    )}
                    {downloading[link.url] && (
                      <button style={{ alignSelf: 'center' }} className={styles.downloadButtonInactive} disabled>
                        Downloading...
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
            {showDownloadAll && (
              <div className={styles.downloadAllSection}>
                <div className={styles.linkSection}>
                  <p style={{ fontSize: 22, fontWeight: 'bold' }}>&nbsp;</p>
                  {!downloading['all'] && (
                    <button className={styles.downloadButton} onClick={onDownloadAll}>
                      Download All
                    </button>
                  )}
                  {downloading['all'] && (
                    <button className={styles.downloadButtonInactive} disabled>
                      Downloading...
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.grid}>
          <div />
          <div style={{ lineHeight: 1.6 }}>
            <p>
              *Only the images listed above are purchased and eligible for download. Need to purchase more downloads? &nbsp;
              <a style={{ color: '#444575' }} href="https://my.photoday.com" target="_blank" rel="noreferrer">
                Head on back over to the gallery!
              </a>
            </p>
          </div>
        </div>
        <div className={styles.grid}>
          <div />
          <div style={{ paddingBottom: 120, lineHeight: 1.6 }}>
            <p>
              Need help downloading your photos? &nbsp;
              <a
                style={{ color: '#444575' }}
                href="https://support.photoday.io/en/articles/2224876-how-can-i-download-my-photos"
                target="_blank"
                rel="noreferrer"
              >
                Click here!
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
