import React from 'react';
import logo from './assets/logo.png';
import styles from './Header.module.css';

type HeaderProps = React.HTMLAttributes<HTMLElement>;

const App: React.FC<HeaderProps> = (props) => {
  return (
    <header className={styles.header} {...props}>
      <nav className={styles.nav}>
        <img className={styles.logo} src={logo} alt="PhotoDay Logo" />
        <a href="https://my.photoday.com" target="_blank" rel="noreferrer">
          <button>My Galleries</button>
        </a>
      </nav>
    </header>
  );
};

export default App;
