import React from 'react';
import download from '../assets/download.png';
import styles from './Home.module.css';
import Header from "../Header"

const Home: React.FC = () => {
  return (
    <div className="container">
      <Header />
      <div className={styles.grid}>
        <div />
        <div>
          <h1 className={styles.bannerText}>Want to download your photos?</h1>
          <p className={styles.bannerInfo}>You should have received your download link via e-mail or SMS. If you have not, please contact your studio or PhotoDay for more information.</p>
        </div>
        <div className={styles.rightPanel}>
          <img src={download} alt="Download Icon" />
        </div>
        <div />
        <div />
        <div>
          <a href = "https://support.photoday.io/en/articles/2224876-how-can-i-download-my-photos" target="_blank" rel="noreferrer">
            <p>Need help downloading your photos? Click here!</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
