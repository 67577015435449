import React from 'react';
import notFound404 from '../assets/404.png';
import styles from './NotFound.module.css';
import Header from "../Header"

const NotFound: React.FC = () => {
  return (
    <div className="container">
      <Header />
      <div className={styles.grid}>
        <div />
        <div>
          <h1 className={styles.bannerText}>Page not found...</h1>
          <p className={styles.bannerInfo}>We couldn't find what you're looking for here.</p>
        </div>
        <div className={styles.rightPanel}>
          <img src={notFound404} alt="not found icon" />
        </div>
        <div />
        <div />
        <div>
          <a href = "https://support.photoday.io/en/articles/2224876-how-can-i-download-my-photos" target="_blank" rel="noreferrer">
            <p>Need help downloading your photos? Click here!</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
